<template>
  <b-modal centered modal-class="upgrade_limit_model"
           no-close-on-backdrop
           id="increase-limits-dialog-v2" hide-footer
           hide-header>

      <div @click="close()" class="pt-[0.75rem] float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 40 40" fill="none">
          <path d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" stroke="#2560D7" stroke-width="2.9" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M20 12.7998V27.1998" stroke="#2560D7" stroke-width="2.9" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.7999 20H27.1999" stroke="#2560D7" stroke-width="2.9" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[0.875rem] font-bold leading-5">{{ getEditModifierLimits && getEditModifierLimits._id ? 'Edit Modifier Limits' : 'Upgrade Limits' }}</p>
        <p class="pt-1 !text-center text">Please enter quantities to upgrade your limit.</p>
      </div>
      <div class="px-10 pt-6 w-full h-full">
        <div v-if="loader" class="flex flex-col h-full justify-center items-center">
          <Loader color="text-[#2560D7] w-[2rem] !h-[2rem]"></Loader>
        </div>
        <IncreaseLimitsComponentV2 v-else :profile="getProfile" :subscription="getSubscription" @update="upgradeAddonsCallback($event)" />
      </div>
  </b-modal>
</template>
<script>

import IncreaseLimitsComponent from './IncreaseLimitsComponent'
import { mapGetters } from 'vuex'

export default ({
  name: 'increase-limits-dialog',
  components: {
    IncreaseLimitsComponent,
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    IncreaseLimitsComponentV2: () => import('@/views/pages/setting/billing/dialog/IncreaseLimitsComponentV2.vue'),
  },
  data () {
    return {
      modalId: 'increase-limits-dialog-v2',
      loader: true
    }
  },
  created () {
    setTimeout(() => {
      this.loader = false
    },500)
  },
  computed: {
    ...mapGetters(['getProfile', 'getSubscription', 'getEditModifierLimits', 'self']),
  },
  methods: {
    upgradeAddonsCallback (data) {
      // this.loaders.increase_limits = true
      this.$store.dispatch('toastNotification', { message: 'Your purchase has been successfully processed. Please wait for few seconds while your limits are upgraded.' })
      setTimeout(async () => {
        await this.$store.dispatch('fetchProfile')
        this.getUserSubscription()
        // await this.$store.dispatch('fetchPlan')
        // this.loaders.increase_limits = false
        this.$bvModal.hide(this.modalId)
      }, 5000)
    },
    close () {
      this.$parent.closeModal()
      this.$bvModal.hide('increase-limits-dialog-v2')
    }
  }
})
</script>
